// Use the color variables directly in Sass
$color-java: #1BC9E1;
$color-white: #fff;
$color-background: #1d1d1d;
$color-gull-grey: #98A7B9;
$color-pickled-bluewood: #2F3B51;
$color-river-bed: #44546A;
$color-genoa: #147685;
$color-light-grasgreen: #A7F55E;
$color-artyclick-amber: #FFC000;
$color-coral-red: #FF3A3C;

// Define your color variables
:root {
  /* CI colors */
  --color-white: #{$color-white};
  --color-background: #{$color-background};
  --color-gull-grey: #{$color-gull-grey};
  --color-pickled-bluewood: #{$color-pickled-bluewood};
  --color-river-bed: #{$color-river-bed};
  --color-java: #{$color-java};
  --color-genoa: #{$color-genoa};
  --color-light-grasgreen: #{$color-light-grasgreen};
  --color-artyclick-amber: #{$color-artyclick-amber};
  --color-coral-red: #{$color-coral-red};
}

// Generate text colors using Sass functions
.text-java-100 {
  color: lighten($color-java, 40%);
}
.text-java-200 {
  color: lighten($color-java, 30%);
}
.text-java-300 {
  color: lighten($color-java, 20%);
}
.text-java-400 {
  color: lighten($color-java, 10%);
}
.text-java-500 {
  color: $color-java; // Use the base color directly for .text-java-500
}
.text-java-600 {
  color: darken($color-java, 10%);
}
.text-java-700 {
  color: darken($color-java, 20%);
}
.text-java-800 {
  color: darken($color-java, 30%);
}
.text-java-900 {
  color: darken($color-java, 40%);
}

.bg-java-100 {
  background-color: lighten($color-java, 40%);
}
.bg-java-200 {
  background-color: lighten($color-java, 30%);
}
.bg-java-300 {
  background-color: lighten($color-java, 20%);
}
.bg-java-400 {
  background-color: lighten($color-java, 10%);
}
.bg-java-500 {
  background-color: $color-java; // Use the base color directly for .text-java-500
}
.bg-java-600 {
  background-color: darken($color-java, 10%);
}
.bg-java-700 {
  background-color: darken($color-java, 20%);
}
.bg-java-800 {
  background-color: darken($color-java, 30%);
}
.bg-java-900 {
  background-color: darken($color-java, 40%);
}

.text-light-grasgreen-100 {
  color: lighten($color-light-grasgreen, 40%);
}
.text-light-grasgreen-200 {
  color: lighten($color-light-grasgreen, 30%);
}
.text-light-grasgreen-300 {
  color: lighten($color-light-grasgreen, 20%);
}
.text-light-grasgreen-400 {
  color: lighten($color-light-grasgreen, 10%);
}
.text-light-grasgreen-500 {
  color: $color-light-grasgreen; // Use the base color directly for .text-light-grasgreen-500
}
.text-light-grasgreen-600 {
  color: darken($color-light-grasgreen, 10%);
}
.text-light-grasgreen-700 {
  color: darken($color-light-grasgreen, 20%);
}
.text-light-grasgreen-800 {
  color: darken($color-light-grasgreen, 30%);
}
.text-light-grasgreen-900 {
  color: darken($color-light-grasgreen, 40%);
}

.bg-light-grasgreen-100 {
  background-color: lighten($color-light-grasgreen, 40%);
}
.bg-light-grasgreen-200 {
  background-color: lighten($color-light-grasgreen, 30%);
}
.bg-light-grasgreen-300 {
  background-color: lighten($color-light-grasgreen, 20%);
}
.bg-light-grasgreen-400 {
  background-color: lighten($color-light-grasgreen, 10%);
}
.bg-light-grasgreen-500 {
  background-color: $color-light-grasgreen; // Use the base color directly for .text-light-grasgreen-500
}
.bg-light-grasgreen-600 {
  background-color: darken($color-light-grasgreen, 10%);
}
.bg-light-grasgreen-700 {
  background-color: darken($color-light-grasgreen, 20%);
}
.bg-light-grasgreen-800 {
  background-color: darken($color-light-grasgreen, 30%);
}
.bg-light-grasgreen-900 {
  background-color: darken($color-light-grasgreen, 40%);
}

.text-artyclick-amber-100 {
  color: lighten($color-artyclick-amber, 40%);
}
.text-artyclick-amber-200 {
  color: lighten($color-artyclick-amber, 30%);
}
.text-artyclick-amber-300 {
  color: lighten($color-artyclick-amber, 20%);
}
.text-artyclick-amber-400 {
  color: lighten($color-artyclick-amber, 10%);
}
.text-artyclick-amber-500 {
  color: $color-artyclick-amber; // Use the base color directly for .text-artyclick-amber-500
}
.text-artyclick-amber-600 {
  color: darken($color-artyclick-amber, 10%);
}
.text-artyclick-amber-700 {
  color: darken($color-artyclick-amber, 20%);
}
.text-artyclick-amber-800 {
  color: darken($color-artyclick-amber, 30%);
}
.text-artyclick-amber-900 {
  color: darken($color-artyclick-amber, 40%);
}

.bg-artyclick-amber-100 {
  background-color: lighten($color-artyclick-amber, 40%);
}
.bg-artyclick-amber-200 {
  background-color: lighten($color-artyclick-amber, 30%);
}
.bg-artyclick-amber-300 {
  background-color: lighten($color-artyclick-amber, 20%);
}
.bg-artyclick-amber-400 {
  background-color: lighten($color-artyclick-amber, 10%);
}
.bg-artyclick-amber-500 {
  background-color: $color-artyclick-amber; // Use the base color directly for .text-artyclick-amber-500
}
.bg-artyclick-amber-600 {
  background-color: darken($color-artyclick-amber, 10%);
}
.bg-artyclick-amber-700 {
  background-color: darken($color-artyclick-amber, 20%);
}
.bg-artyclick-amber-800 {
  background-color: darken($color-artyclick-amber, 30%);
}
.bg-artyclick-amber-900 {
  background-color: darken($color-artyclick-amber, 40%);
}

.text-coral-red-100 {
  color: lighten($color-coral-red, 40%);
}
.text-coral-red-200 {
  color: lighten($color-coral-red, 30%);
}
.text-coral-red-300 {
  color: lighten($color-coral-red, 20%);
}
.text-coral-red-400 {
  color: lighten($color-coral-red, 10%);
}
.text-coral-red-500 {
  color: $color-coral-red; // Use the base color directly for .text-coral-red-500
}
.text-coral-red-600 {
  color: darken($color-coral-red, 10%);
}
.text-coral-red-700 {
  color: darken($color-coral-red, 20%);
}
.text-coral-red-800 {
  color: darken($color-coral-red, 30%);
}
.text-coral-red-900 {
  color: darken($color-coral-red, 40%);
}

.bg-coral-red-100 {
  background-color: lighten($color-coral-red, 40%);
}
.bg-coral-red-200 {
  background-color: lighten($color-coral-red, 30%);
}
.bg-coral-red-300 {
  background-color: lighten($color-coral-red, 20%);
}
.bg-coral-red-400 {
  background-color: lighten($color-coral-red, 10%);
}
.bg-coral-red-500 {
  background-color: $color-coral-red; // Use the base color directly for .text-coral-red-500
}
.bg-coral-red-600 {
  background-color: darken($color-coral-red, 10%);
}
.bg-coral-red-700 {
  background-color: darken($color-coral-red, 20%);
}
.bg-coral-red-800 {
  background-color: darken($color-coral-red, 30%);
}
.bg-coral-red-900 {
  background-color: darken($color-coral-red, 40%);
}