/* styles.css */
@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#logo-container {
  position: relative;
  width: 100%;
  height: 100%;
}

#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('images/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
}