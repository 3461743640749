@tailwind base;

@layer base {
  @font-face {
    font-family: 'AllenSans';
    src: url('fonts/AllenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'AllenSans';
    src: url('fonts/AllenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'AllenSans';
    src: url('fonts/AllenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
}